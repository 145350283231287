import useSWR from 'swr';
import useCustomRouter from '@hooks/useCustomRouter';
import { getProductBannerComponent, getProductBannerPackages } from '@api/interfaces/componentApi';
import useStore from '@hooks/useStore';
import useCart from '@hooks/useCart';

type keyType = {
  id: string;
  filterQuery?: string;
  sortQuery?: string;
};

const fetcher = async ({ id, filterQuery, sortQuery }: keyType) => {
  const [productBanner, packageComponents] = await Promise.all([
    getProductBannerComponent(id, undefined, '999', undefined, filterQuery, sortQuery),
    getProductBannerPackages(id),
  ]);
  return { productBanner, packageComponents };
};

const useProductBannerItems = (id: string, displayFilters?: boolean) => {
  const { store } = useStore();
  const { cart } = useCart();
  const router = useCustomRouter();
  const filterQuery = displayFilters ? (router?.query?.q as string) : undefined;
  const sortQuery = displayFilters ? (router?.query?.sort as string) : undefined;
  const key = {
    endpoint: '/productBannerComponent/{componentId} /productBannerComponent/packages/{componentId}',
    id,
    filterQuery,
    sortQuery,
    displayFilters,
    storeId: store?.storeId,
    slot: cart?.slot?.code,
  };

  // TODO investigate how we can handle this on server aswell
  // wait with fetching until store has been fetched
  //const shouldFetch = Boolean(store);
  const shouldFetch = true;

  const { data, error, isLoading } = useSWR(shouldFetch ? key : null, fetcher, { keepPreviousData: true });

  return {
    component: data?.productBanner as ProductBannerComponentType,
    packages: data?.packageComponents as { [key: string]: ImageGridComponentType },
    isLoading,
    error,
  };
};

export default useProductBannerItems;
