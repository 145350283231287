import { rem } from 'polished';
import styled, { css } from 'styled-components';
import Config from '@config';
import variables from '@styles/variables';
import { TextProps } from './Text';

export const CommonStyledText = css<TextProps>`
  margin: 0;

  ${(props) =>
    props.color === 'red' &&
    css`
      color: ${variables.colors.primary};
    `}

  ${(props) =>
    props.color === 'black' &&
    css`
      color: ${variables.colors.lighterBlack};
    `}

  ${(props) =>
    props.color === 'gray' &&
    css`
      color: ${variables.colors.darkerGray};
    `}

  ${(props) =>
    props.color === 'darkGray' &&
    css`
      color: ${variables.colors.darkGray};
    `}

  ${(props) =>
    props.color === 'white' &&
    css`
      color: ${variables.colors.white};
    `}

  ${(props) =>
    props.color === 'blue' &&
    css`
      color: ${variables.colors.highlight};
    `}

  ${(props) =>
    props.color === 'error' &&
    css`
      color: ${variables.colors.primaryDark};
    `}

  ${(props) =>
    props.align === 'left' &&
    css`
      text-align: left;
    `}

  ${(props) =>
    props.align === 'center' &&
    css`
      text-align: center;
    `}

  ${(props) =>
    props.align === 'right' &&
    css`
      text-align: right;
    `}


  ${(props) =>
    props.type === 'intro' &&
    css`
      font-family: ${variables.fonts.fontGothamBook};
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    `}

  ${(props) =>
    props.type === 'body' &&
    css`
      font-family: ${variables.fonts.fontGothamBook};
      font-size: ${rem(15)};
      line-height: ${rem(24)};

      strong {
        font-family: ${variables.fonts.fontGothamMedium};
      }

      ${props.size === 'small' &&
      css`
        font-size: ${rem(13)};
        line-height: ${rem(20)};
      `}

      ${props.size === 'responsive' &&
      css`
        font-size: ${rem(13)};
        line-height: ${rem(20)};

        ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
          font-size: ${rem(15)};
          line-height: ${rem(24)};
        }
      `}
    `}

  ${(props) =>
    props.type === 'detail' &&
    css`
      font-family: ${variables.fonts.fontGothamBook};
      font-size: ${rem(11)};
      line-height: ${rem(16)};

      ${props.size === 'responsive' &&
      css`
        font-size: ${rem(11)};
        line-height: ${rem(16)};

        ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
          font-size: ${rem(13)};
          line-height: ${rem(20)};
        }
      `}
    `}

  ${(props) =>
    props.type === 'subhead' &&
    css`
      font-family: ${variables.fonts.fontGothamMedium};

      font-size: ${rem(15)};
      line-height: ${rem(24)};

      ${props.size === 'small' &&
      css`
        font-size: ${rem(13)};
        line-height: ${rem(20)};
      `}

      ${props.size === 'large' &&
      css`
        font-size: ${rem(18)};
        line-height: ${rem(28)};
        font-weight: 500;
      `}

      ${props.size === 'responsive' &&
      css`
        font-size: ${rem(13)};
        line-height: ${rem(20)};

        ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
          font-size: ${rem(15)};
          line-height: ${rem(24)};
        }
      `}
    `}

  ${(props) =>
    props.type === 'subtitle' &&
    css`
      font-family: ${variables.fonts.fontGothamMedium};

      font-size: ${rem(15)};
      line-height: ${rem(24)};

      ${props.size === 'large' &&
      css`
        font-size: ${rem(18)};
        line-height: ${rem(28)};
      `}

      ${props.size === 'small' &&
      css`
        font-size: ${rem(13)};
        line-height: ${rem(16)};
      `}

      ${props.size === 'xsmall' &&
      css`
        font-size: ${rem(11)};
        line-height: ${rem(16)};
      `}
    `}

  ${(props) =>
    props.type === 'label' &&
    css`
      font-family: ${variables.fonts.fontGothamMedium};
      text-transform: uppercase;
      font-size: ${rem(13)};
      line-height: ${rem(20)};

      ${props.size === 'large' &&
      css`
        font-size: ${rem(15)};
        line-height: ${rem(24)};
      `}
    `}


  ${(props) =>
    props.type === 'price' &&
    css`
      font-family: ${variables.fonts.fontGothamSerifBold};

      font-size: ${rem(20)};
      line-height: ${rem(24)};

      ${props.size === 'small' &&
      css`
        font-size: ${rem(13)};
      `}

      ${props.size === 'xsmall' &&
      css`
        font-family: ${variables.fonts.fontGothamMedium};
        font-size: ${rem(11)};
      `}

      ${props.size === 'medium' &&
      css`
        font-size: ${rem(16)};
        line-height: ${rem(20)};
      `}

      ${props.size === 'large' &&
      css`
        font-size: ${rem(28)};
        line-height: ${rem(32)};
      `}
    `}

  ${(props) =>
    props.ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`;

export const StyledTextParagraph = styled.p<TextProps>`
  ${CommonStyledText}
`;

export const StyledTextSpan = styled.span<TextProps>`
  ${CommonStyledText}
`;
