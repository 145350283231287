import Config from '@config';
import { CmsApi } from '@occ/api-client';

const api = new CmsApi({
  baseUrl: '/axfoodcommercewebservices/v2',
});

export const findComponentsByIds = async (componentIds: Array<string> | string, storeId?: string) => {
  const componentIdsArray = Array.isArray(componentIds) ? componentIds : componentIds.split(',');
  const res = await api.findComponentsByIds(Config.SITE_NAME, {
    componentIds: componentIdsArray,
    storeId,
    pageSize: componentIdsArray.length,
  });
  return res.data;
};
