import trackLinkNavigation from '@helpers/analyticsHelpers/trackLinkNavigation';
import paths from '@constants/paths';
import {
  StyledCopyright,
  StyledFooter,
  StyledFooterBottomSection,
  StyledFooterColumn,
  StyledFooterGrid,
  StyledFooterLinks,
  StyledFooterLinksLink,
  StyledFooterLinksText,
  StyledFooterNav,
  StyledFooterNavHeading,
  StyledFooterNavHeadingLink,
  StyledFooterNavIcon,
  StyledSocialMediaImg,
  StyledSocialMediaWrapper,
  StyledTrustedSite,
  StyledTrustedSiteIcon,
} from '@components/organisms/Footer/Footer.styles';
import Link from '@atoms/Link/Link';
import MobileFooter from './MobileFooter/MobileFooter';
import useCmsComponents from '@hooks/useCmsComponents';
import type { ComponentAdaptedData, NavigationNodeWsDTO } from '@occ/api-client';
import { ContentSlotWsDTOWithComponent } from '@api/interfaces/collectSlots';
import { IconProps } from '@atoms/Icon/Icon';
import MapPin from '@icons/map-pin.svg';
import WillysPlus from '@icons/willys-plus.svg';
import Offer from '@icons/offer.svg';
import useTranslation from 'next-translate/useTranslation';
import useResponsive from '@hooks/useResponsive';

interface Props {
  slot: ContentSlotWsDTOWithComponent;
}

interface FooterComponentAdaptedData extends ComponentAdaptedData {
  linkName: string;
}

const Footer = ({ slot }: Props) => {
  const { t } = useTranslation('footer');
  const { fromDesktop } = useResponsive();
  const components = slot.component;
  const footerComponent = components?.find((item) => item.uid === 'FooterComponent');
  const icons: { [key: string]: IconProps } = {
    WillysFooterStoreFinderNode: { svg: MapPin, size: 24 },
    WillysFooterWillysPlusNode: { svg: WillysPlus, size: 24 },
    WillysFooterCampaignNode: { svg: Offer, size: 24 },
  };

  let footerFirstSection: NavigationNodeWsDTO | undefined;
  let footerSecondSection: NavigationNodeWsDTO | undefined;
  (footerComponent as ComponentAdaptedData)?.navigationNode?.children?.forEach((element: NavigationNodeWsDTO) => {
    if (element.uid === 'WillysFooterLinksLeftNode') footerFirstSection = element;
    if (element.uid === 'WillysFooterLinksRightNode') footerSecondSection = element;
  });

  const { data: footerComponents } = useCmsComponents(JSON.stringify('WillysFooterCopyrightText'));

  const linkComponent = footerComponents?.component?.filter(
    (component): component is FooterComponentAdaptedData => 'linkName' in component
  )?.[0];

  return (
    <StyledFooter>
      {components && (
        <StyledFooterGrid>
          <StyledFooterColumn>
            {fromDesktop &&
              footerFirstSection !== undefined &&
              footerFirstSection.children?.map((child) => (
                <StyledFooterNav key={`footer-nav-${child.uid}`} aria-label={child.title}>
                  <StyledFooterNavHeading>
                    <StyledFooterNavHeadingLink
                      href={child?.links?.[0]?.url || ''}
                      onClick={() => trackLinkNavigation('footer', child.title)}
                      external={child?.links?.[0]?.external || false}
                    >
                      {child.title}
                    </StyledFooterNavHeadingLink>
                  </StyledFooterNavHeading>
                  {child.children?.map((grandChild) => (
                    <StyledFooterLinks data-id={grandChild.uid} key={`footer-links-${grandChild.uid}`}>
                      {grandChild.uid === 'CookieSettings' ? (
                        <StyledFooterLinksText className="optanon-toggle-display">
                          {grandChild.title}
                        </StyledFooterLinksText>
                      ) : (
                        <StyledFooterLinksLink
                          href={grandChild?.links?.[0]?.url || ''}
                          onClick={() => trackLinkNavigation('footer', grandChild.title)}
                          external={child?.links?.[0]?.external || false}
                        >
                          {grandChild.title}
                        </StyledFooterLinksLink>
                      )}
                    </StyledFooterLinks>
                  ))}
                </StyledFooterNav>
              ))}
            {!fromDesktop && footerFirstSection !== undefined && (
              <MobileFooter node={footerFirstSection} parentNodes={[]} isRoot />
            )}
          </StyledFooterColumn>

          <StyledFooterColumn>
            {footerSecondSection?.children?.map((child) => {
              const iconProps = icons[child.uid as keyof typeof icons] || {};
              return (
                <StyledFooterNav key={`footer-column-${child.uid}`} aria-label={child.title}>
                  <StyledFooterNavHeading>
                    {!!icons[child.uid as keyof typeof icons] && <StyledFooterNavIcon {...iconProps} color="primary" />}
                    <StyledFooterNavHeadingLink
                      href={child?.links?.length ? child.links[0].url || '' : ''}
                      onClick={() => trackLinkNavigation('footer', child.title)}
                      external={child?.links ? child.links[0].external || false : false}
                    >
                      {child.title}
                    </StyledFooterNavHeadingLink>
                  </StyledFooterNavHeading>
                </StyledFooterNav>
              );
            })}
            <StyledSocialMediaWrapper>
              <Link href={paths.external.FACEBOOK} target="_blank">
                <StyledSocialMediaImg title="Facebook" src="/icons/logos/Facebook.svg" />
              </Link>
              <Link href={paths.external.INSTAGRAM} target="_blank">
                <StyledSocialMediaImg title="Instagram" src="/icons/logos/Instagram.svg" />
              </Link>
              <Link href={paths.external.TIKTOK} target="_blank">
                <StyledSocialMediaImg title="TikTok" src="/icons/logos/TikTok.svg" />
              </Link>
              <Link href={paths.external.YOUTUBE} target="_blank">
                <StyledSocialMediaImg title="Youtube" src="/icons/logos/Youtube.svg" />
              </Link>
              <Link href={paths.external.LINKEDIN} target="_blank">
                <StyledSocialMediaImg title="Linkedin" src="/icons/logos/Linkedin.svg" />
              </Link>
            </StyledSocialMediaWrapper>
          </StyledFooterColumn>
        </StyledFooterGrid>
      )}

      <StyledFooterBottomSection>
        <StyledTrustedSite>
          <Link href={paths.external.MPA} target="_blank" rel="noopener noreferrer">
            <StyledTrustedSiteIcon src="/icons/pharmacy.svg" alt={t('accessibility->pharmaceuticalVerificationLabel')} />
          </Link>
        </StyledTrustedSite>
        <StyledCopyright type="body" size="small" color="white">
          {linkComponent && linkComponent.linkName}
        </StyledCopyright>
      </StyledFooterBottomSection>
    </StyledFooter>
  );
};

export default Footer;
