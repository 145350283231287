import { AriaRole, CSSProperties, ReactNode } from 'react';
import { StyledTextParagraph, StyledTextSpan } from './Text.styles';

export type TextType = 'intro' | 'body' | 'detail' | 'subhead' | 'subtitle' | 'label' | 'price';
export type SizeType = 'xsmall' | 'small' | 'medium' | 'large' | 'responsive';
export type ColorType = 'black' | 'red' | 'white' | 'gray' | 'darkGray' | 'blue' | 'error' | 'darkRed';
type Alignment = 'left' | 'right' | 'center';

export interface TextProps {
  element?: 'p' | 'span';
  type?: TextType; // TODO: ask why type was required, not used everywhere in <Text>
  color?: ColorType;
  size?: SizeType;
  align?: Alignment;
  style?: CSSProperties;
  children?: ReactNode;
  title?: string;
  'data-testid'?: string;
  className?: string;
  ellipsis?: boolean;
  role?: AriaRole;
}

const Text = ({
  element = 'p',
  color = 'black',
  className,
  type,
  size,
  align,
  children,
  style,
  ellipsis,
  title = '',
  ...props
}: TextProps) => {
  const StyledElement = element === 'span' ? StyledTextSpan : StyledTextParagraph;

  return (
    <StyledElement
      color={color}
      type={type}
      size={size}
      align={align}
      style={style}
      title={title}
      data-testid={props['data-testid']}
      ellipsis={ellipsis}
      className={className}
      role={props.role}
    >
      {children}
    </StyledElement>
  );
};

export default Text;
