import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import { ButtonThemesStyles, LinkButtonThemesStyles } from '@atoms/Button/ButtonThemes.styles';
import { ButtonSizesStyles, LinkSizesStyles } from '@atoms/Button/ButtonSizes.styles';
import Spinner from '@molecules/Spinner/Spinner';

interface ButtonProps {
  theme: ButtonTheme;
  size: ButtonSize;
  color?: string;
  background?: string;
  borderColor?: string;
  centered?: boolean;
  isSubmitting?: boolean;
  isSelected?: boolean;
}

export const StyledBoxButton = css<ButtonProps>`
  align-items: center;
  border-radius: ${rem(35)};
  border: ${rem(1)} solid;
  cursor: pointer;
  display: flex;
  font-family: ${variables.fonts.fontGothamMedium};
  font-size: ${rem(13)};
  gap: ${rem(8)};
  justify-content: center;
  margin: 0;
  text-align: center;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  outline-offset: ${rem(3)};

  /* Sizes */
  ${ButtonSizesStyles} /* Themes */ ${ButtonThemesStyles}

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};

      &:hover {
        @media (hover: hover) and (pointer: fine) {
          color: ${props.color};
        }
      }
    `}

  ${(props) =>
    props.background &&
    css`
      background: ${props.background};

      &:hover:not(:disabled) {
        @media (hover: hover) and (pointer: fine) {
          background: ${props.background};
          opacity: 0.8;
        }
      }
    `}

  ${(props) =>
    props.borderColor &&
    css`
      border-color: ${props.borderColor};
    `}

  ${(props) =>
    props.centered &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}
`;

export const StyledLinkButton = css`
  all: unset;
  font-family: ${variables.fonts.fontGothamMedium};
  font-size: ${rem(15)};
  text-decoration: none;
  cursor: pointer;

  ${LinkButtonThemesStyles}
  ${LinkSizesStyles}
`;

export const StyledButton = styled.button<ButtonProps>`
  ${(props) => (props.theme.indexOf('link') !== -1 ? StyledLinkButton : StyledBoxButton)}
  &:disabled {
    cursor: default;
  }

  ${(props) =>
    props.isSubmitting &&
    css`
      color: transparent !important;
      position: relative;

      > :not(${StyledSpinner}) {
        visibility: hidden;
      }
    `};
`;

export const StyledSpinner = styled(Spinner)`
  position: absolute;
`;
