import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';

export const StyledSkipToMain = styled.div<{ isFocused: boolean }>`
  position: absolute;
  z-index: ${variables.zIndex.toolbar + 2};
  left: -100%;
  top: -100%;

  ${(props) =>
    props.isFocused &&
    css`
      left: ${rem(20)};
      top: ${rem(20)};
    `}
`;
