import React, { ReactNode, useMemo } from 'react';
import CmsComponent from '@organisms/CmsComponent/CmsComponent';
import { StyledPosition3Slot } from '@components/templates/WillysShoppingTemplate/WillysShoppingTemplate.style';
import NavigationBreadcrumbComponent from '@molecules/NavigationBreadcrumbComponent/NavigationBreadcrumbComponent';
import GridContainer from '@organisms/GridContainer/GridContainer';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import styled from 'styled-components';
import { rem } from 'polished';
import CmsContentSlot from '@atoms/CmsContentSlot/CmsContentSlot';
import type { PageWithSlots } from '@api/interfaces/pageApi';
import collectSlots from '@api/interfaces/collectSlots';
import useSmartEdit from '@hooks/useSmartEdit';
import useSymplifyExperiment from '@utility/ABTest/Symplify/useSymplifyExperiment';
import type { ComponentWsDTO } from '@occ/api-client';
import LastBuyComponent from '@organisms/LastBuyComponent/LastBuyComponent';

interface Props {
  cmsPage: PageWithSlots;
  children?: ReactNode;
  shoppingSlots: string[];
}

const StyledGridContainer = styled(GridContainer)`
  padding-top: ${rem(20)};
`;

const convertProductBeam = (component: ComponentWsDTO) => {
  return <LastBuyComponent data={component as CMSProductBannerComponentType} />;
};

const WillysShoppingTemplate = ({ cmsPage, children, shoppingSlots }: Props) => {
  const sideMenuIsOpen = useAppSelector(selectSideNavMenuIsOpen);
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const { Position1, Position3 } = collectSlots(cmsPage.slots, shoppingSlots);
  const { breadcrumbs = [] } = cmsPage;
  const breadCrumbDataArray = Array.isArray(breadcrumbs) ? breadcrumbs : [breadcrumbs];
  const { isSmartEditEnabled } = useSmartEdit();

  const variant = useSymplifyExperiment('Do not forget to buy', 'Original');

  // TODO: PLEASE remove this when the last buy A/B test is done
  const { Position3Filtered, movedProductBeam } = useMemo(() => {
    let Position3Filtered = Position3;
    let movedProductBeam = null;

    if (Position3Filtered) {
      Position3Filtered.component = Position3Filtered.component.filter((component) => {
        if (
          component.typeCode === 'AxfoodProductBannerComponent' &&
          (component as CMSProductBannerComponentType).displayType === 'DO_NOT_FORGET_TO_BUY' &&
          (variant === 'B' || variant === 'C')
        ) {
          movedProductBeam = component;
          return false;
        }
        return true;
      });
    }

    return { Position3Filtered, movedProductBeam };
  }, [Position3, variant]);

  return (
    <>
      {(isSmartEditEnabled || Position1?.component?.length > 0 || movedProductBeam !== null) && (
        <CmsContentSlot slotData={Position1} id="Position1Slot" data-testid="Position1Slot">
          {breadCrumbDataArray.length > 0 && <NavigationBreadcrumbComponent data={breadCrumbDataArray} />}
          {Position1?.component?.map((child) => (
            <CmsComponent key={child.uuid} component={child} />
          ))}
        </CmsContentSlot>
      )}

      {movedProductBeam !== null && (
        <StyledGridContainer addPadding={sideMenuIsOpen || isCartPreviewOpen}>
          {/* Moved product beam from Position3 to Position1 */}
          {convertProductBeam(movedProductBeam)}
        </StyledGridContainer>
      )}

      {children && children}

      {(isSmartEditEnabled || Position3Filtered?.component?.length > 0) && (
        <StyledPosition3Slot slotData={Position3Filtered} id="Position3Slot" data-testid="Position3Slot">
          <StyledGridContainer addPadding={sideMenuIsOpen || isCartPreviewOpen}>
            {Position3Filtered?.component?.map((child) => {
              return <CmsComponent key={child.uuid} component={child} />;
            })}
          </StyledGridContainer>
        </StyledPosition3Slot>
      )}
    </>
  );
};

export default WillysShoppingTemplate;
