import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useAppDispatch';
import useTranslation from 'next-translate/useTranslation';
import { toggleMenu } from '@slices/menuSlice';
import { trackToggleMenu } from '@helpers/analyticsHelpers/trackMenuAction';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import { StyledMenuButton, StyledMenuButtonWrapper, StyledMenuText } from './MenuButton.styles';

const MenuButton = () => {
  const { t } = useTranslation('menubutton');
  const isOpen: boolean = useAppSelector(selectSideNavMenuIsOpen);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleMenu(localStorage.getItem('willys.sidenav-left') === 'true' || false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledMenuButtonWrapper>
      <StyledMenuButton
        theme="primary"
        background="black"
        onClick={() => {
          localStorage.setItem('willys.sidenav-left', (!isOpen).toString());
          trackToggleMenu(!isOpen);
          dispatch(toggleMenu(!isOpen));

          // Focus on 1st nav button after menu button is clicked, set timeout to allow menu to open first
          setTimeout(() => {
            const firstButton = document.querySelector<HTMLButtonElement>('nav button:first-of-type');
            firstButton?.focus();
          }, 0);
        }}
      >
        <StyledMenuText>{isOpen ? t('menubutton->close') : t('menubutton->menu')}</StyledMenuText>
      </StyledMenuButton>
    </StyledMenuButtonWrapper>
  );
};

export default MenuButton;
