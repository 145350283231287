import { StyledSkipToMain } from './SkipToMain.styles';
import Button from '@atoms/Button/Button';
import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

const SkipToMain = () => {
  const { t } = useTranslation('common');
  const [isFocused, setIsFocused] = useState(false);

  const onClick = () => {
    const main = document.querySelector('main');
    if (main) {
      // Select all focusable elements within the <main> element
      const focusableElements = main.querySelectorAll<HTMLAnchorElement>(`
      a[href],
      button:not([disabled]),
      input:not([disabled]):not([type="hidden"]),
      select:not([disabled]),
      textarea:not([disabled]),
      [tabindex]:not([tabindex="-1"])
    `);

      // Focus the first focusable element, if any
      if (focusableElements.length > 0) {
        focusableElements[0].focus();
      }
    }
  };

  return (
    <StyledSkipToMain isFocused={isFocused} role="navigation">
      <Button theme="primary" onClick={onClick} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}>
        {t('navigation->skipToMainContent')}
      </Button>
    </StyledSkipToMain>
  );
};

export default SkipToMain;
