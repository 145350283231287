import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { getItemsForYear } from '@api/interfaces/componentApi';
import SelectOption from '@molecules/Select/Option';
import SelectBox from '@molecules/Select/SelectBox';
import useTranslation from 'next-translate/useTranslation';
import paths from '@constants/paths';
import Link from '@atoms/Link/Link';
import { useRouter } from 'next/router';
import {
  StyledCenterText,
  StyledIcon,
  StyledLeftText,
  StyledNewsComponent,
  StyledNewsLine,
  StyledSelect,
  StyledShowMoreButton,
} from './NewsComponent.styles';
import AccordionDown from '@icons/accordion-down_small.svg';
import { trackFilteredOnYear, trackShowMore } from '@helpers/analyticsHelpers/trackNewsComponent';
import useCmsComponents from '@hooks/useCmsComponents';

type NewsComponentProps = {
  data: NewsComponentType;
};

const defaultValue = {
  value: '',
  label: '',
  selected: false,
};
const displayAmount = 6;

const NewsComponent = ({ data }: NewsComponentProps) => {
  const { t } = useTranslation('newsComponent');
  const router = useRouter();
  const { data: swrData, isLoading } = useCmsComponents(JSON.stringify(data?.years?.replace(/[[\]"]+/g, '').split(',')));
  const [newsData, setNewsData] = useState<NewsItemListType>([] as NewsItemListType);
  const [showIndex, setShowIndex] = useState<number>(displayAmount);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<OptionType>(defaultValue as OptionType);
  const [selectedOptionList, setSelectedOptionList] = useState<OptionType[]>([defaultValue] as OptionType[]);

  const getItems = async (year: string) => {
    setShowIndex(displayAmount);
    const items: any = await getItemsForYear(year);
    if (items.data.length > displayAmount) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
    setNewsData(items.data);
  };

  const showMore = () => {
    setIsSubmitting(true);
    if (showIndex + displayAmount > newsData.length) {
      setShowIndex(showIndex + (newsData.length - showIndex));
      setShowButton(false);
    } else {
      setShowIndex(showIndex + displayAmount);
    }
    setIsSubmitting(false);
    trackShowMore();
  };

  const onChangeHandler = async (option: OptionType) => {
    setSelectedOptionList((previousOptions) =>
      previousOptions.map((previousOption) => ({ ...previousOption, selected: previousOption.value === option.value }))
    );
    setSelectedOption(option);
    trackFilteredOnYear(option);
    await getItems(option.value);
  };

  useEffect(() => {
    if (swrData && !isLoading) {
      const tempOptionsList = swrData.component?.map((option) => {
        return { value: option.uid, label: option.name, selected: false };
      });
      tempOptionsList?.sort((a, b) => {
        return parseInt(b.label ? b.label : '', 10) - parseInt(a.label ? a.label : '', 10);
      });

      setSelectedOptionList(tempOptionsList as OptionType[]);
      setSelectedOption(tempOptionsList?.at(0) as OptionType);
      getItems(tempOptionsList?.at(0)?.value as string);
    }
  }, [isLoading, swrData]);

  return (
    <StyledNewsComponent>
      {selectedOptionList && selectedOption && (
        <StyledSelect
          required
          options={selectedOptionList}
          testId="select-box-selector"
          optionComponent={({ option, isSelected, onItemClickHandler }) => (
            <SelectOption
              key={option.value}
              option={option}
              isSelected={isSelected}
              onItemClick={() => {
                onItemClickHandler(option);
              }}
            />
          )}
          selectBoxComponent={() => <SelectBox currentSelection={selectedOption} />}
          onChange={onChangeHandler}
          avoidViewport
        />
      )}
      {newsData.length > 0 && (
        <>
          <StyledNewsLine>
            <StyledLeftText type="label">{t('headingDate')}</StyledLeftText>
            <StyledCenterText type="label">{t('headingSubject')}</StyledCenterText>
          </StyledNewsLine>
          {newsData.slice(0, showIndex).map((newsItem) => {
            const encodedUid = encodeURIComponent(newsItem.uid);
            return (
              <Link
                href={{
                  pathname: router.pathname,
                  query: { ...router.query },
                }}
                asProp={`${paths.ARTICLE.NEWSDETAIL}/${encodedUid}`}
                internal
                key={newsItem.uid.replaceAll(' ', '')}
              >
                <StyledNewsLine data-testid="newsitem">
                  <StyledLeftText type="label" size="large">
                    {format(newsItem.date, 'yyMMdd')}
                  </StyledLeftText>
                  <StyledCenterText type="body">{newsItem.title}</StyledCenterText>
                  <StyledIcon svg={AccordionDown} size={12} />
                </StyledNewsLine>
              </Link>
            );
          })}
          {showButton && (
            <StyledShowMoreButton onClick={showMore} isSubmitting={isSubmitting}>
              {t('showMore')}
            </StyledShowMoreButton>
          )}
        </>
      )}
    </StyledNewsComponent>
  );
};

export default NewsComponent;
