import Heading from '@atoms/Heading/Heading';
import Link from '@atoms/Link/Link';
import Icon from '@atoms/Icon/Icon';
import Text from '@atoms/Text/Text';
import { useState } from 'react';
import useCustomRouter from '@hooks/useCustomRouter';
import useTranslation from 'next-translate/useTranslation';
import webToAppApi from '@api/web-to-app';
import { trackClickInStoreDetail } from '@helpers/analyticsHelpers/trackStoreFinder';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import Button from '@atoms/Button/Button';
import useUserAgent from '@hooks/useUserAgent';
import { StyledContentWrapper, StyledItemWrapper, StyledLink } from './StoreInfoComponent.styles';
import Offer from '@icons/offer.svg';
import { extractStoreId } from '@helpers/storeHelper';
import useCmsComponents from '@hooks/useCmsComponents';

const defaultAmountOfFeatures = 6;

type StoreInfoComponentProps = {
  data: StoreInfoComponentType;
};

const hiddenFeatures = [
  'StoreFeatureId-0000000100', // Burek
  'StoreFeatureId-0000000059', // Picadeli
  'StoreFeatureId-0000000049', // Färskt bröd
  'StoreFeatureId-0000000101', // Grill
];

const sortSpecialOpeningDays = (
  specialOpeningDays: StoreInfoComponentDataOpeningHoursType
): Array<StoreInfoComponentDataSpecialOpeningDay> => {
  // Sort keys numerically:
  return Object.entries(specialOpeningDays)
    .sort(([key1], [key2]) => parseInt(key1.replace(/\D/g, '')) - parseInt(key2.replace(/\D/g, '')))
    .map(([, value]) => value);
};

const StoreInfoComponent = ({ data }: StoreInfoComponentProps) => {
  const sideMenuIsOpen = useAppSelector(selectSideNavMenuIsOpen);
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const [showOpeningHours, setShowOpeningHours] = useState(false);
  const [showAllFeatures, setShowAllFeatures] = useState(false);
  const router = useCustomRouter();
  const { t } = useTranslation('storePage');
  const { isNativeApp } = useUserAgent();
  const storeId = extractStoreId(router.query?.storeParameters);
  const { data: storeInfoComponents } = useCmsComponents(JSON.stringify(data.uid), storeId);
  const storeInfoData = storeInfoComponents?.component?.filter(
    (component): component is StoreInfoComponentDataType => 'store' in component
  )?.[0];
  const amountOfFeatures = Object.keys(storeInfoData?.storeFeatures || []).length;

  return (
    <>
      <StyledItemWrapper gap={20}>
        <Heading variant="h3">{t('storeInfo')}</Heading>
        {!isNativeApp && storeInfoData?.store?.flyerURL && storeInfoData.isOpen === 'true' && (
          <Text type="body">
            <StyledLink
              href={storeInfoData.store.flyerURL}
              onClick={() => {
                trackClickInStoreDetail(t('showFlyer'));
                webToAppApi.actionRouteToAddress(storeInfoData.store.flyerURL || '');
              }}
              target="_blank"
            >
              <Icon svg={Offer} size={20} color="highlight" /> {t('showFlyer')}
            </StyledLink>
          </Text>
        )}
      </StyledItemWrapper>
      <StyledContentWrapper menuCartOpen={sideMenuIsOpen && isCartPreviewOpen} cartOpen={isCartPreviewOpen}>
        {storeInfoData && (
          <>
            {storeInfoData.mergedOpeningDays && (
              <StyledItemWrapper gap={8}>
                <Text type="label" size="large">
                  {t('openingHours')}
                </Text>

                <div>
                  {Object.entries(storeInfoData.mergedOpeningDays)
                    // Sort by day of week (MONDAY, SATURDAY, SUNDAY)
                    .sort(([key1], [key2]) => key1.localeCompare(key2))
                    .map(([key, value]) => {
                      return (
                        <Text key={key} type="body">{`${
                          value.name2 ? `${t(value.name1)}-${t(value.name2)}` : t(value.name1)
                        } ${value.openingHours}-${value.closingHours}`}</Text>
                      );
                    })}

                  {Object.keys(storeInfoData?.specialOpeningDays).length !== 0 && (
                    <Button
                      theme="link"
                      onClick={() => {
                        trackClickInStoreDetail(t('weekendOpeningHours'));
                        setShowOpeningHours(!showOpeningHours);
                      }}
                    >
                      <Text element="span" type="subhead" color="blue">
                        {t('weekendOpeningHours')} {showOpeningHours ? '-' : '+'}
                      </Text>
                    </Button>
                  )}

                  {showOpeningHours && (
                    <div>
                      {sortSpecialOpeningDays(storeInfoData.specialOpeningDays).map((value) => {
                        return (
                          <Text
                            key={value.name1}
                            type="body"
                          >{`${value.name1} ${value.openingHours}-${value.closingHours}`}</Text>
                        );
                      })}
                    </div>
                  )}
                </div>
              </StyledItemWrapper>
            )}

            <StyledItemWrapper gap={8}>
              <Text type="label" size="large">
                {t('findUs')}
              </Text>

              <div>
                <Text type="body">{storeInfoData?.storeStreetName}</Text>
                <Text type="body">{`${storeInfoData?.storePostalCode} ${storeInfoData?.storeTown}`}</Text>

                <Text type="body">
                  <Link
                    href={`https://maps.google.se?saddr=current+location&daddr=Willys+${storeInfoData?.storeTown}+${storeInfoData?.storeStreetName}+${storeInfoData?.storePostalCode}`}
                    onClick={() => {
                      trackClickInStoreDetail(t('directions'));
                      webToAppApi.actionRouteToAddress(
                        `${storeInfoData?.storeStreetName}, ${storeInfoData?.storePostalCode}, ${storeInfoData?.storeTown}`
                      );
                    }}
                    target="_blank"
                  >
                    {t('directions')}
                  </Link>
                </Text>

                {!isNativeApp && (
                  <Text type="body">
                    <Link
                      href={`https://www.google.se/maps?q=Willys+${storeInfoData?.storeTown}+${storeInfoData?.storeStreetName}+${storeInfoData?.storePostalCode}`}
                      onClick={() => {
                        trackClickInStoreDetail(t('showOnMap'));
                        webToAppApi.actionRouteToAddress(
                          `${storeInfoData?.storeStreetName}, ${storeInfoData?.storePostalCode}, ${storeInfoData?.storeTown}`
                        );
                      }}
                      target="_blank"
                    >
                      {t('showOnMap')}
                    </Link>
                  </Text>
                )}
              </div>
            </StyledItemWrapper>

            <StyledItemWrapper gap={8}>
              <Text type="label" size="large">
                {t('contact')}
              </Text>
              <Text type="body">
                <a href={`tel:${storeInfoData?.storePhone}`}>{storeInfoData?.storePhone}</a>
              </Text>
            </StyledItemWrapper>

            {storeInfoData.storeFeatures && !isNativeApp && (
              <StyledItemWrapper gap={8}>
                <Text type="label" size="large">
                  {t('service')}
                </Text>

                <div>
                  {Object.entries(storeInfoData.storeFeatures).map(([key, feature], index) => {
                    if (hiddenFeatures.includes(key) || (!showAllFeatures && index >= defaultAmountOfFeatures)) {
                      return null;
                    }
                    return (
                      <Text type="body" key={index}>
                        {feature}
                      </Text>
                    );
                  })}

                  {amountOfFeatures > defaultAmountOfFeatures && !showAllFeatures && (
                    <Button theme="link" onClick={() => setShowAllFeatures(true)}>
                      <Text element="span" type="subhead" color="blue">
                        {t('showAll')}
                      </Text>
                    </Button>
                  )}
                </div>
              </StyledItemWrapper>
            )}
            {isNativeApp && storeInfoData.storeFeatures && (
              <StyledItemWrapper gap={8}>
                <Text type="label" size="large">
                  {t('service')}
                </Text>

                <div>
                  {Object.entries(storeInfoData.storeFeatures).map(([key, feature], index) => {
                    if (hiddenFeatures.includes(key)) {
                      return null;
                    }
                    return (
                      <Text type="body" key={index}>
                        {feature}
                      </Text>
                    );
                  })}
                </div>
              </StyledItemWrapper>
            )}
          </>
        )}
      </StyledContentWrapper>
    </>
  );
};

export default StoreInfoComponent;
