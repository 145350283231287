import styled, { css } from 'styled-components';
import Alert from '@molecules/Alert/Alert';
import Button from '@atoms/Button/Button';
import { rem } from 'polished';
import variables from '@styles/variables';

export const StyledOrderNoticeAlert = styled(Alert)<{ stickyTop: boolean; animate: boolean }>`
  position: sticky;
  top: ${rem(60)};
  z-index: ${Number(variables.zIndex.toolbar) - 1};
  transition: top 0.2s ease;

  ${(props) =>
    props.stickyTop &&
    css`
      top: 0 !important;
    `}

  ${(props) =>
    props.animate &&
    css`
      transition: top 0.4s ease;
    `}
`;

export const StyledOrderNoticeButton = styled(Button)`
  display: inline-flex;
  color: ${variables.colors.white};
  text-decoration: underline;
  font-size: ${rem(13)};
  line-height: ${rem(20)};

  &:hover {
    color: ${variables.colors.white};
  }
`;
