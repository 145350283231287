import { ReactNode } from 'react';
import KEYS from '@helpers/keys';
import { StyledContainer, StyledRadioInfo, StyledRadioInput, StyledRadioLabel } from './Radio.styles';

interface Props {
  bold?: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
  changeOnKeyEnter?: boolean;
  id: string;
  disabled?: boolean;
  children?: ReactNode;
  info?: ReactNode;
  className?: string;
  theme?: 'primary' | 'secondary' | 'checkmark';
  testId?: string;
  tabIndex?: number;
}

const Radio = ({
  bold = false,
  checked,
  disabled,
  onChange,
  changeOnKeyEnter,
  id,
  children,
  info,
  className,
  theme = 'primary',
  testId,
  tabIndex,
}: Props) => {
  return (
    <StyledContainer className={className}>
      <StyledRadioInput
        type="radio"
        checked={checked}
        disabled={disabled}
        theme={theme}
        data-testid={testId}
        id={id}
        onChange={(e) => {
          !disabled && onChange(!checked);
        }}
        onKeyDown={(e) => {
          !disabled && changeOnKeyEnter && e.key === KEYS.ENTER && onChange(!checked);
        }}
        tabIndex={tabIndex}
      />
      <StyledRadioLabel htmlFor={id} bold={bold}>
        {children}
      </StyledRadioLabel>
      {info && <StyledRadioInfo>{info}</StyledRadioInfo>}
    </StyledContainer>
  );
};

export default Radio;
