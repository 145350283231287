import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import variables from '@styles/variables';

type ColorValueType = { white: string; black: string; red: string; gray: string; darkRed: string };

const colors: ColorValueType = {
  red: variables.colors.primary,
  white: variables.colors.white,
  black: variables.colors.lighterBlack,
  gray: variables.colors.darkerGray,
  darkRed: variables.colors.primaryDark,
};

export interface HeadingProps {
  size?: string;
  color?: HeadingColors;
  isLabel?: boolean;
}

export const headingBaseStyles = css`
  font-family: ${variables.fonts.fontGothamSerifBold};
  margin: 0;
  padding: 0;
`;

const labelStyles = css`
  font-family: ${variables.fonts.fontGothamMedium};
  text-transform: uppercase;
  font-size: ${rem(13)};
  font-weight: 500;
  white-space: nowrap;
`;

export const H1Styles = css<HeadingProps>`
  ${headingBaseStyles};
  color: ${(props) => props.color && colors[props.color]};
  font-size: ${(props) => (props.size === 'large' ? rem(32) : rem(30))};
  line-height: ${(props) => (props.size === 'large' ? rem(36) : rem(36))};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    font-size: ${(props) => (props.size === 'large' ? rem(40) : rem(30))};
    line-height: ${(props) => (props.size === 'large' ? rem(44) : rem(36))};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    font-size: ${(props) => (props.size === 'large' ? rem(60) : rem(32))};
    line-height: ${(props) => (props.size === 'large' ? rem(64) : rem(36))};
  }

  ${(props) => props.isLabel && labelStyles};
`;

export const StyledH1 = styled.h1<HeadingProps>`
  ${H1Styles}
`;

export const H2Styles = css<HeadingProps>`
  ${headingBaseStyles};
  color: ${(props) => props.color && colors[props.color]};
  font-size: ${(props) => (props.size === 'small' ? rem(22) : rem(30))};
  line-height: ${(props) => (props.size === 'small' ? rem(28) : rem(36))};

  ${Config.MEDIA.FROM_DESKTOP} {
    font-size: ${(props) => (props.size === 'small' ? rem(22) : rem(32))};
    line-height: ${(props) => (props.size === 'small' ? rem(28) : rem(36))};
  }

  ${(props) => props.isLabel && labelStyles};
`;

export const StyledH2 = styled.h2<HeadingProps>`
  ${H2Styles}
`;

export const H3Styles = css<HeadingProps>`
  ${headingBaseStyles};
  color: ${(props) => props.color && colors[props.color]};
  font-size: ${rem(20)};
  line-height: ${rem(28)};

  ${(props) => props.isLabel && labelStyles};
`;

export const StyledH3 = styled.h3<HeadingProps>`
  ${H3Styles}
`;
