import { useAppDispatch } from '@hooks/useAppDispatch';
import { toggleMenu } from '@slices/menuSlice';
import { trackToggleCategory, trackOpenCategory } from '@helpers/analyticsHelpers/trackMenuAction';
import { useMediaQuery } from 'react-responsive';
import Config from '@config';
import Icon from '@atoms/Icon/Icon';
import {
  StyledAccordionNavNode,
  StyledAccordionNavNodeContent,
  StyledAccordionNavNodeLinkButton,
  StyledAccordionNavNodeToggleButton,
  StyledAccordionNavNodeLink,
  StyledAccordionNavNodeList,
} from './AccordionNavNode.styles';
import MinusSmall from '@icons/minus_small.svg';
import PlusSmall from '@icons/plus_small.svg';
import useTranslation from 'next-translate/useTranslation';
import useResponsive from '@hooks/useResponsive';
import useCustomRouter from '@hooks/useCustomRouter';

interface Props {
  node: AccordionNavNodeType;
  parentNodes: Array<NavigationNode>;
  navigationNodes: Array<NavigationNode>;
  setNavigationNodes: (navigationNodes: Array<NavigationNode>) => void;
  noBorder: boolean;
  setActiveNode: (node: ActiveNodeType) => void;
  activeNode: ActiveNodeType;
  setExpandedNodes: (nodes: Array<NavigationNode>) => void;
  expandedNodes: Array<NavigationNode>;
}

const AccordionNavNode = ({
  node,
  parentNodes,
  noBorder,
  navigationNodes,
  setNavigationNodes,
  setActiveNode,
  activeNode,
  setExpandedNodes,
  expandedNodes,
}: Props) => {
  const dispatch = useAppDispatch();
  const hasChildren: boolean = node?.children?.length > 0;
  const isTopLevel: boolean = parentNodes.length === 0;
  const activeNodeHasNoParents: boolean = activeNode.parents.length === 0;
  const currentNodeHierarchy: Array<NavigationNode> = parentNodes.concat({ id: node.category });
  const currentNodeExpanded = expandedNodes.map((n: NavigationNode) => n?.id).includes(node.category);
  const isHighlightedNodes = activeNode.parents.map((n: NavigationNode) => n?.id).includes(node.category);
  const navigationNodeExpanded = navigationNodes.map((n: NavigationNode) => n?.id).includes(node.category);
  const { fromDesktop } = useResponsive();
  const { t } = useTranslation('common');
  const router = useCustomRouter();

  const onClickHandler = () => {
    if (currentNodeExpanded) {
      // Close redux node
      setExpandedNodes(parentNodes);
      setNavigationNodes(parentNodes);
    } else if (
      (isTopLevel ? node.category : parentNodes[0]?.id) ===
      (activeNodeHasNoParents ? activeNode.id : activeNode.parents[0]?.id)
    ) {
      // Open redux node
      setExpandedNodes(currentNodeHierarchy);
    } else {
      // Toggle navigation node
      navigationNodeExpanded ? setNavigationNodes(parentNodes) : setNavigationNodes(currentNodeHierarchy);
    }

    trackToggleCategory(!navigationNodeExpanded, currentNodeHierarchy);
  };

  const dispatchActiveAndExpand = () => {
    setActiveNode({ id: node.category, parents: parentNodes });
    setExpandedNodes(currentNodeHierarchy);
    if (!fromDesktop && !hasChildren) {
      localStorage.setItem('willys.sidenav-left', 'false');
      dispatch(toggleMenu(false));
    }
  };

  return (
    <StyledAccordionNavNode
      noBorder={noBorder}
      isExpanded={currentNodeExpanded}
      isActive={activeNode.id === node.category}
      isTopLevel={isTopLevel}
    >
      <StyledAccordionNavNodeContent>
        <StyledAccordionNavNodeLinkButton
          type="button"
          role="menuitem"
          onClick={() => {
            dispatchActiveAndExpand();
            onClickHandler();
            trackOpenCategory(currentNodeHierarchy);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              router.push(node.url);
            }
          }}
        >
          <StyledAccordionNavNodeLink
            internal
            isHighlighted={currentNodeExpanded || isHighlightedNodes}
            isActive={activeNode.id === node.category}
            hasChildren={hasChildren}
            variant="sidenav"
            href={node.url}
            customStyle={{
              paddingLeft: `${
                ((currentNodeExpanded && isTopLevel) || isHighlightedNodes ? 22 : 24) + parentNodes.length * 12
              }px`,
            }}
            tabIndex={-1}
          >
            {node.title}
          </StyledAccordionNavNodeLink>
        </StyledAccordionNavNodeLinkButton>
        {hasChildren && (
          <StyledAccordionNavNodeToggleButton
            isHighlighted={currentNodeExpanded}
            isActive={activeNode.id === node.category}
            type="button"
            role="menuitem"
            aria-label={currentNodeExpanded || navigationNodeExpanded ? t('minimizeLabel') : t('expandLabel')}
            onClick={onClickHandler}
          >
            <Icon
              svg={currentNodeExpanded || navigationNodeExpanded ? MinusSmall : PlusSmall}
              size={12}
              color={
                currentNodeExpanded || isHighlightedNodes || activeNode.id === node.category ? 'primary' : 'lighterBlack'
              }
              data-testid={`icon-${currentNodeExpanded || navigationNodeExpanded ? 'minus' : 'plus'}`}
            />
          </StyledAccordionNavNodeToggleButton>
        )}
      </StyledAccordionNavNodeContent>

      {hasChildren && (currentNodeExpanded || navigationNodeExpanded) && (
        <StyledAccordionNavNodeList>
          {node.children.map((child) => {
            return (
              <AccordionNavNode
                node={child}
                parentNodes={currentNodeHierarchy}
                key={`node-child-${child.title}`}
                noBorder={currentNodeExpanded || navigationNodeExpanded}
                navigationNodes={navigationNodes}
                setNavigationNodes={setNavigationNodes}
                setActiveNode={setActiveNode}
                activeNode={activeNode}
                setExpandedNodes={setExpandedNodes}
                expandedNodes={expandedNodes}
              />
            );
          })}
        </StyledAccordionNavNodeList>
      )}
    </StyledAccordionNavNode>
  );
};

export default AccordionNavNode;
