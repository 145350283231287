import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Icon from '@atoms/Icon/Icon';
import Button from '@atoms/Button/Button';

export const StyledAnimationWrapper = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  right: ${rem(-2)};
  max-width: 0;
  transition: max-width ease ${variables.animation.layout};
  overflow: hidden;
  height: calc(var(--doc-height) + ${rem(1)});
  background-color: ${variables.colors.white};
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.show &&
    css`
      max-width: 100vw;
    `}
`;

export const StyledSection = styled.div`
  height: 100%;
  background-color: ${variables.colors.white};
  border: ${rem(1)} solid ${variables.colors.lightGray};
  display: flex;
  flex-direction: column;
  padding-bottom: ${rem(24)};
`;

export const StyledSectionWrapper = styled.div`
  z-index: ${variables.zIndex.mobileMenu};
  position: relative;
`;

export const StyledCloseButton = styled(Button)`
  position: absolute;
  top: ${rem(20)};
  right: ${rem(20)};
  z-index: 1;
`;
