import React, { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { StyledAnimationWrapper, StyledCloseButton, StyledSection, StyledSectionWrapper } from './SlideInSection.styles';
import Backdrop from '@atoms/Backdrop/Backdrop';
import KEYS from '@helpers/keys';
import Close from '@icons/close_small.svg';
import { toggleSlideInSection } from '@slices/slideInSectionSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import Icon from '@atoms/Icon/Icon';

interface Props {
  children?: ReactNode;
  onClose?: (event?: MouseEvent) => void;
  closeOnEscape?: boolean;
  backdropZIndex?: number;
  hasCloseIcon?: boolean;
}

const SlideInSection = ({ children, onClose, closeOnEscape, backdropZIndex, hasCloseIcon }: Props) => {
  const [backdropVisibility, setBackdropVisibility] = useState(true);
  const slideInSectionRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  const closeHandler = (event?: MouseEvent) => {
    if (onClose) {
      setBackdropVisibility(false);
      onClose(event);
    }
  };

  const keyPressHandler = (e: KeyboardEvent) => {
    if (closeOnEscape) {
      if (e.key === KEYS.ESCAPE) {
        closeHandler();
      }
    }
  };

  useEffect(() => {
    // Focus on first button in the slide in section when it opens, for A11y
    if (backdropVisibility) {
      dispatch(toggleSlideInSection(true));
      const firstButton = slideInSectionRef.current?.querySelector('button');
      firstButton?.focus();
    }
  }, [backdropVisibility, dispatch]);

  useEffect(() => {
    window.addEventListener('keyup', keyPressHandler);

    return () => {
      window.removeEventListener('keyup', keyPressHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {backdropVisibility && (
        <Backdrop display={backdropVisibility} callback={closeHandler} zIndex={backdropZIndex}>
          <StyledSectionWrapper ref={slideInSectionRef}>
            <StyledAnimationWrapper show={backdropVisibility}>
              <StyledSection>
                {hasCloseIcon && (
                  <StyledCloseButton
                    onClick={closeHandler}
                    tabIndex={2}
                    theme="transparent"
                    data-testid="slidein-close-button"
                  >
                    <Icon svg={Close} size={16} />
                  </StyledCloseButton>
                )}
                {children}
              </StyledSection>
            </StyledAnimationWrapper>
          </StyledSectionWrapper>
        </Backdrop>
      )}
    </>
  );
};

export default SlideInSection;
