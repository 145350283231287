import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Tab from '@molecules/Tabs/Tab';
import { VariantType } from './Tabs';

export const StyledTabContainer = styled.div<{ last: boolean; variant: VariantType }>`
  display: flex;
  margin-bottom: ${rem(20)};
  width: 100%;
  justify-content: center;

  ${(props) =>
    props.variant === 'product' &&
    css`
      position: relative;
      justify-content: flex-start;
    `};

  ${(props) =>
    props.variant === 'rounded' &&
    css`
      border: ${rem(1)} solid ${variables.colors.darkGray};
      border-radius: ${rem(26)};
      padding: ${rem(1)};
    `};

  ${(props) =>
    props.last &&
    css`
      justify-content: flex-end;
    `};
`;

export const StyledTab = styled(Tab)<{
  variant: VariantType;
  active: boolean;
  setLeftMargin: boolean;
  slideIndex: number;
}>`
  margin: 0;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  padding: 0 ${rem(12)};
  height: ${rem(52)};
  color: ${variables.colors.lighterBlack};
  background-color: ${variables.colors.white};
  font-family: ${variables.fonts.fontGothamMedium};
  font-size: ${rem(15)};
  flex-basis: 0;

  &:focus {
    z-index: 1;
  }

  ${(props) =>
    props.variant === 'box' &&
    css`
      border-bottom: ${rem(2)} solid ${variables.colors.mediumGray};
      color: ${props.active ? variables.colors.primary : variables.colors.lighterBlack} !important;
      border-color: ${props.active && variables.colors.primary};
    `};

  ${(props) =>
    props.variant === 'product' &&
    css`
      position: relative;
      border-bottom: ${rem(2)} solid ${variables.colors.mediumGray};
      color: ${props.active && variables.colors.primary};
      border-color: ${props.active && variables.colors.primary};
    `};

  ${(props) =>
    props.variant === 'rounded' &&
    css`
      text-transform: uppercase;
      height: ${rem(40)};
      color: ${variables.colors.lighterBlack} !important; /* TODO remove important when converting link */
      font-size: ${rem(11)};
      flex-basis: 100%;

      border-radius: ${props.active && rem(26)};
      background-color: ${props.active && variables.colors.primary};
      color: ${props.active && variables.colors.white} !important; /* TODO remove important when converting link */
    `};

  ${(props) =>
    props.setLeftMargin &&
    css`
      left: ${`-${(props.slideIndex - 1) * 200 - 20}px`};
    `};
`;
